import { css, StyleSheet } from 'aphrodite/no-important'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import React, { useState } from 'react'
import Content from '../components/Content'
import Copy from '../components/Copy'
import Stage from '../components/Stage'
import TextInput from '../components/TextInput'
import Default from '../templates/default'

export interface KontaktProps {
    data: {
        stageImage: {
            childImageSharp: {
                fluid: FluidObject
            }
        }
    }
}

const Kontakt: React.FC<KontaktProps> = ({ data }: KontaktProps): JSX.Element => {
    const [name, setName] = useState('')
    const [mail, setMail] = useState('')
    const [message, setMessage] = useState('')

    return (
        <Default>
            <Content align="center">
                <Stage image={data.stageImage.childImageSharp} small={true} />

                <Copy>
                    Ihr wünscht eine ganz individuelle Planung Eures Junggesellinnenabschiedes oder habt Fragen zu
                    unserem Angebot? Dann nehmt Kontakt mit uns auf! Wir freuen uns sehr von Euch zu hören.
                </Copy>

                <form className={css(styles.form)} name="contact" method="POST" netlify data-netlify="true">
                    <TextInput name="Name" value={name} handleChange={e => setName(e.currentTarget.value)} />
                    <TextInput name="E-Mail" value={mail} handleChange={e => setMail(e.currentTarget.value)} />
                    <TextInput name="Nachricht" value={message} handleChange={e => setMessage(e.currentTarget.value)} />
                </form>

                <Copy>
                    <a className={css(styles.link)} href="tel:+4922116868130">
                        <img className={css(styles.icon)} src={require('../img/icons/phone.svg')} />
                        0221 168 681 30
                    </a>
                </Copy>

                <Copy>
                    <a className={css(styles.link)} href="mailto:hallo@bride-surprise.com">
                        <img className={css(styles.icon)} src={require('../img/icons/mail.svg')} />
                        hallo@bride-surprise.com
                    </a>
                </Copy>
            </Content>
        </Default>
    )
}
const styles = StyleSheet.create({
    link: {
        color: '#000',
        fontSize: 50,
        display: 'flex',
        lineHeight: 1,
        alignItems: 'center',
        textDecoration: 'none',
        borderBottom: '2px solid transparent',
        transition: 'border-bottom 0.1s ease-in-out',

        ':hover': {
            borderBottom: '2px solid #000',
        },
    },

    icon: {
        height: 44,
        marginRight: 20,
    },

    form: {
        display: 'none',
    },
})

export const query = graphql`
    query {
        stageImage: file(relativePath: { eq: "stage/kontakt.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200, maxHeight: 250, quality: 75, cropFocus: CENTER) {
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                }
            }
        }
    }
`

export default Kontakt
