import { css, StyleSheet } from 'aphrodite/no-important'
import React, { ChangeEvent } from 'react'

export interface TextInputProps {
    name: string
    value?: string
    handleChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const TextInput: React.FC<TextInputProps> = ({ name, value, handleChange }: TextInputProps): JSX.Element => (
    <label className={css(styles.TextInput)}>
        {name}
        <input onChange={handleChange} type="text" value={value} />
    </label>
)

const styles = StyleSheet.create({
    TextInput: {
        display: 'grid',
    },
})

export default TextInput
